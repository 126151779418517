{
  "common": {
    "language": "语系",
    "lang": {
      "en": "English",
      "th": "ภาษาไทย",
      "zhHant": "简体中文"
    },
    "menuList": {
      "about": "特色",
      "gameTypes": "游戏类型",
      "ourService": "服务项目",
      "partnership": "合作伙伴",
      "contact": "联络我们"
    },
    "welcome": "欢迎来到",
    "slogan": "轻松玩 赢大奖",
    "scroll": "更多",
    "etc": "等",
    "playGame": "试玩",
    "detail": "详细介绍",
    "return": "返回",
    "similarGame": "相似游戏",
    "about": {
      "title": {
        "service": "24小时"
      },
      "description": {
        "gameTypes": "游戏类型丰富",
        "service": "24/7即时客服",
        "currency": "支援币别",
        "device": "全装置支援",
        "language": "多语系"
      }
    },
    "gameType": {
      "hot": "热门",
      "frequent": "高频率",
      "graph": "图表",
      "liveStream": "视频",
      "lottery": "彩票",
      "rng": "RNG",
      "ball": "彩球",
      "stock": "股票",
      "description": {
        "graph": "即时走势",
        "liveStream": "视频直播开奖任你选",
        "lottery": "多款热门彩票",
        "rng": "24小时不间断随时玩",
        "ball": "知名彩球游戏",
        "stock": "多国知名股票指数"
      }
    },
    "ourService": {
      "title": {
        "api": "API串接",
        "admin": "后台管理系统",
        "risk": "风险即时监控"
      },
      "description": {
        "api-1": "多钱包：一次性对接，快速简单",
        "admin-1": "账号管理",
        "admin-2": "多种报表支援",
        "admin-3": "24/7客服支援",
        "risk-1": "热门号码设定",
        "risk-2": "赔率自动降级",
        "risk-3": "总量达标通知"
      }
    },
    "gameInfo": {
      "awardPeriod": "开奖周期",
      "betType": "玩法总量",
      "bundleType": "包牌类型",
      "maxPayout": "最大赔率",
      "deviceSupport": "支援装置",
      "languageSupport": "支援语系",
      "rule": "规则说明"
    },
    "bundleType": {
      "19d": "19门",
      "box": "排列",
      "combo": "组合"
    },
    "gameName": {
      "1-0001": "泰国彩票",
      "1-0002": "越南 (河内) 彩票",
      "1-0003": "寮国彩票",
      "1-0005": "马来西亚 (万能万字) 彩票",
      "1-0006": "新加坡 (4D) 彩票",
      "1-0009": "泰国 (SET) 股票",
      "1-0010": "印度 (Sensex) 股票",
      "1-0011": "三星彩",
      "1-0012": "日本 (Nikkei225) 股票",
      "1-0013": "韩国 (KTOP30) 股票",
      "1-0014": "越南 (胡志明) 彩票",
      "1-0015": "越南 (岘港) 彩票",
      "1-0016": "越南 (庆和) 彩票",
      "1-0017": "比特币",
      "1-0018": "Yee Kee",
      "2-0002": "墨西哥彩球 (Melate)",
      "2-0003": "12星座",
      "2-0004": "竞速十",
      "2-0005": "幸运赛车",
      "2-0006": "墨西哥彩球 (Melate Retro)",
      "2-0007": "双赢彩",
      "2-0008": "基诺彩"
    },
    "awardPeriod": {
      "1-0001": "每月的1号、16号",
      "1-0002": "每天",
      "1-0003": "星期一、星期三及星期五",
      "1-0005": "星期三、星期六及星期天",
      "1-0006": "星期三、星期六及星期天",
      "1-0009": "星期一至星期五",
      "1-0010": "星期一至星期五",
      "1-0011": "每5分钟",
      "1-0012": "星期一至星期五",
      "1-0013": "星期一至星期五",
      "1-0014": "星期一及星期六",
      "1-0015": "星期三及星期六",
      "1-0016": "星期三及星期天",
      "1-0017": "每1分钟",
      "1-0018": "每一小时",
      "2-0002": "星期三及星期天",
      "2-0003": "每30秒",
      "2-0004": "每5分钟",
      "2-0005": "每3分钟",
      "2-0006": "星期二及星期六",
      "2-0007": "每1分钟",
      "2-0008": "每1分钟"
    },
    "gameRules": {
      "1-0001": "泰国彩票会在每个月的1 号和16 号发布开奖，在开奖当天的15:20 (UTC+7) 的时间结束卖卷，奖金会在每期开奖30分钟后自动汇入玩家帐户，并可在开奖前一天的20:00:00 (UTC+7)开始下注下一期（如果在那一个月有更换开奖日期会先在网上提前通知，随时可以在页面查询）",
      "1-0002": "每日开奖 (国定假日休息)，开奖当天18:00 (UTC+7)结束卖券，奖金会在每期开奖30分钟后自动汇入玩家帐户，并可在19:00 (UTC+7)开始下注下一期",
      "1-0003": "开奖日期每个礼拜一,礼拜三和礼拜五，20:00 (UTC+7)结束卖券，奖金会在每期开奖35分钟后自动汇入玩家帐户，并可在21:00 (UTC+7)开始下注下一期",
      "1-0005": "马来西亚 (万能万字) 彩票每周星期三、六、日 19:00（时区为 UTC +8)，开奖前半小时结束该时段卖券，奖金会在每期开奖2小时后自动汇入玩家帐户",
      "1-0006": "新加坡 (4D) 彩票每周星期三、六、日 18:30（时区为 UTC +8)，开奖前半小时结束该时段卖券，奖金会在每期开奖1小时5分钟后自动汇入玩家帐户",
      "1-0009": "每天16:40(UTC+7:00)，开奖前半小时结束该时段卖券，奖金会在每期开奖35分钟后自动汇入玩家帐户",
      "1-0010": "每天15:30(UTC+5:30)，开奖前半小时结束该时段卖券，并在每个开奖时段开始接受下一期下注，奖金会在每期开奖35分钟后自动汇入玩家帐户",
      "1-0011": "三星彩开奖时间为全天24小时除了08:05-09:00（时区为 UTC+7:00），开奖频率为每五分钟一期，每个时段开奖前10秒结束该时段卖券，奖金会在每期开奖1分钟后自动汇入玩家帐户",
      "1-0012": "星期一至星期五15:00(UTC+9)开奖，每个时段开奖前三十分钟结束该时段卖券，奖金会在每期开奖45分钟后自动汇入玩家帐户",
      "1-0013": "星期一至星期五15:30 (UTC+9)开奖，一天开奖一次，每个时段开奖前半小时结束该时段卖券，奖金会在每期开奖45分钟后自动汇入玩家帐户",
      "1-0014": "星期一及星期六16:00 (UTC+7)开奖，一天开奖一次，每个时段开奖前半小时结束该时段卖券，奖金会在每期开奖55分钟后自动汇入玩家帐户",
      "1-0015": "星期三及星期六17:00 (UTC+7)开奖，一天开奖一次，每个时段开奖前半小时结束该时段卖券，奖金会在每期开奖55分钟后自动汇入玩家帐户",
      "1-0016": "星期三及星期日17:00 (UTC+7)开奖，一天开奖一次，每个时段开奖前半小时结束该时段卖券，奖金会在每期开奖55分钟后自动汇入玩家帐户",
      "1-0017": "比特币开奖时间为全天24小时（时区为 UTC+7:00），开奖频率为每1分钟一期，每个时段开奖前30秒结束该时段卖券，奖金会在每期开奖1分钟后自动汇入玩家帐户。如果您认为该期股价会大于当前位置，请下注「大」；如果您认为该期股价会小于当前位置，请下注「小」。",
      "1-0018": "Yee Kee 全年无休，每小时发布开奖，游戏开始后玩家可以无限次免费发送累积号码，但每次发送之间需至少间隔10秒，在开奖的前两分钟结束卖卷，但玩家仍可持续发送累积号码直到开奖，奖金会在每期开奖后自动汇入玩家帐户，并可立即开始下注下一期。",
      "2-0002": "墨西哥彩球 (Melate) 会在每周的 星期三 和 星期日 发布开奖，在开奖当天的20:45 (CDT/CST) 的时间结束卖卷，奖金会在23:15 ~ 23:45 (CDT/CST)之间自动汇入玩家帐户，并可在21:45 (CDT/CST)开始下注下一期",
      "2-0003": "12星座开奖时间为全天24小时（时区为UTC + 7：00），开奖频率为每30秒一期，奖金会在每期开奖1分钟后自动汇入玩家帐户。您可从01〜12中选择号码进行投注，也可以押注大，小，单，双。开奖时，系统将随机开出1〜3个号码作为中奖号码",
      "2-0004": "竞速十开奖时间为全天24小时除了08:05-09:00（时区为 UTC+7:00），开奖频率为每五分钟一期，每个时段开奖前10秒结束该时段卖券，奖金会在每期开奖1分钟后自动汇入玩家帐户",
      "2-0005": "幸运赛车开奖时间为全天24小时除了08:03-09:00（时区为 UTC+7:00），开奖频率为每3分钟一期，每个时段开奖前10秒结束该时段卖券，奖金会在每期开奖1分钟后自动汇入玩家帐户",
      "2-0006": "墨西哥彩球 (Melate Retro) 会在每周的 星期二 和 星期六 发布开奖，在开奖当天的20:45 (CDT/CST) 的时间结束卖卷，奖金会在23:15 ~ 23:45 (CDT/CST)之间自动汇入玩家帐户，并可在21:45 (CDT/CST)开始下注下一期",
      "2-0007": "双赢彩开奖时间为全天24小时（时区为 UTC+7:00），开奖频率为每1分钟一期。奖金会在每期开奖1分钟后自动汇入玩家帐户。您可从01～12中选择号码进行投注。系统会开出6个号码作为奖号",
      "2-0008": "基诺彩开奖时间为全天24小时（时区为 UTC+7:00），开奖频率为每1分钟一期，奖金会在每期开奖1分钟后自动汇入玩家帐户。基诺彩会从01~80随机抽出20个号码作为开奖结果"
    },
    "error": {
      "common": {
        "forbidden": "权限不足",
        "system": {
          "busy": "系统忙碌中"
        }
      }
    }
  }
}